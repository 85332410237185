import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Home from './pages';

function App() {
  return (
    <div>
      <Routes>
   <Route path="/" element = {<Home/>}/>
    
  </Routes>
    </div>

   
  );
}

export default App;
