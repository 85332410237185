import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {FooterContainer,FooterWrap,FooterLinksContainer,
    FooterLinksWrapper,FooterLinkItems,FooterLinkTitle,FooterLink,
    SocialMedia,SocialMediaWrap,SocialLogo,WebsiteRights,SocialIconLink,SocialIcons}
    from "./FooterElements";
import {FaFacebook,FaTwitter,FaInstagram,FaYoutube,FaLinkedin} from "react-icons/fa";
const Footer = () => {
    const toggleHome= () =>{
        scroll.scrollToTop();
    }
  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to="/aboutUs">How it works</FooterLink>
                            <FooterLink to="/aboutUs">Testimonials</FooterLink>
                            <FooterLink to="/aboutUs">Careers</FooterLink>
                            <FooterLink to="/aboutUs">Investors</FooterLink>
                            <FooterLink to="/aboutUs">Terms Of Services</FooterLink>
                      </FooterLinkItems>
                        <FooterLinkItems>
                        <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to="/aboutUs">How it works</FooterLink>
                            <FooterLink to="/aboutUs">Testimonials</FooterLink>
                            <FooterLink to="/aboutUs">Careers</FooterLink>
                            <FooterLink to="/aboutUs">Investors</FooterLink>
                            <FooterLink to="/aboutUs">Terms Of Services</FooterLink>
                      </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to="/aboutUs">How it works</FooterLink>
                            <FooterLink to="/aboutUs">Testimonials</FooterLink>
                            <FooterLink to="/aboutUs">Careers</FooterLink>
                            <FooterLink to="/aboutUs">Investors</FooterLink>
                            <FooterLink to="/aboutUs">Terms Of Services</FooterLink>
                      </FooterLinkItems>
                        <FooterLinkItems>
                        <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to="/aboutUs">How it works</FooterLink>
                            <FooterLink to="/aboutUs">Testimonials</FooterLink>
                            <FooterLink to="/aboutUs">Careers</FooterLink>
                            <FooterLink to="/aboutUs">Investors</FooterLink>
                            <FooterLink to="/aboutUs">Terms Of Services</FooterLink>
                      </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to="/"  onClick={toggleHome}>
                        SMART.i
                    </SocialLogo>
                    <WebsiteRights>
                        SMART.i © {new Date().getFullYear()} All rights reserved
                    </WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="//www.google.com/" target="_blank"
                        aria-label="Facebook">
                            <FaFacebook/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank"
                        aria-label="Instagram">
                            <FaInstagram/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank"
                        aria-label="Youtube">
                            <FaYoutube/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank"
                        aria-label="Twitter">
                            <FaTwitter/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank"
                        aria-label="Linkedin">
                            <FaLinkedin/>
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer