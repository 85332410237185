export const homeObjOne = {
    id: 'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:true,
    topLine:"Premium Banking",
    headline:"Premium Transaction with 0 fees",
    description:"Premium Transaction with 0 fees",
    buttonLabel:"Get Started",
    imgStart:false,
    img:require("../../images/svg1.svg").default, 
    alt:"car",
    dark:true,
    dark2:true,
    primary:true,
    darkText:false
}

export const homeObjTwo = {
    id: 'discover',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:"Unlimited Asscess",
    headline:"Premium Transaction with 0 fees",
    description:"Premium Transaction with 0 fees",
    buttonLabel:"Get Started",
    imgStart:true,
    img:require("../../images/svg2.svg").default, 
    alt:"piggy",
    dark:false,
    dark2:true,
    primary:false,
    darkText:true
}

export const homeObjThree = {
    id: 'signup',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:"Premium Banking",
    headline:"Premium Transaction with 0 fees",
    description:"Premium Transaction with 0 fees",
    buttonLabel:"Get Started",
    imgStart:false,
    img:require("../../images/svg3.svg").default, 
    alt:"car",
    dark:false,
    dark2:true,
    primary:false,
    darkText:true
}