import React from 'react'
import {ServicesContainer,ServicesH1,ServiesWrapper,ServicesCard
,ServicesIcon,ServicesH2,ServicesP} from './ServicesElements';
import Icon1 from '../../images/svg2.svg';
import Icon2 from '../../images/svg3.svg';
import Icon3 from '../../images/svg4.svg';
const Services = () => {
  return (
    <ServicesContainer id="services">
        <ServicesH1>Our Services</ServicesH1>
            <ServiesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <ServicesH2>Reduce expenses</ServicesH2>
                    <ServicesP>We help reduce your fees 
                        and increase overall revenue</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2}/>
                    <ServicesH2>Virtual offices</ServicesH2>
                    <ServicesP>Can access our platform from anywhere 
                        in the world
                    </ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3}/>
                    <ServicesH2>Premium benefits</ServicesH2>
                    <ServicesP>We help reduce your fees 
                        and increase overall revenue</ServicesP>
                </ServicesCard>
            </ServiesWrapper>
       

    </ServicesContainer>
  )
}

export default Services