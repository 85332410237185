import React ,{useState} from 'react';
import video from "../../videos/video.mp4";
import {Button} from "../ButtonElements";
import { HeroContainer,Herobg,Videobg
,HeroContent,HeroH1,HeroP,HeroBtnWrapper, ArrowForward,ArrowRight} from './HeroElements'
const HeroSection = () => {
    const [hover,setHover]=useState(false);

    const onHover=()=> {
        setHover(!hover);
    }
  return (
    <HeroContainer>
        <Herobg>
           <Videobg autoPlay loop
             muted src={video} type="video/mp4" 
             />
        </Herobg>
        <HeroContent>
            <HeroH1>
                Welcome to SMART.i
            </HeroH1>
            <HeroP>
                Only the content is remaining
            </HeroP>
            <HeroBtnWrapper>
                <Button to="/signin" 
                onMouseEnter={onHover}
                onMouseLeave={onHover}
               >
                    Get Started {hover ? <ArrowForward/> : <ArrowRight/>}
                </Button>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection